/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {

                //match height
                setTimeout(function(){
                  // Need to run it on each element for it to work
                  $('.img-box').matchHeight();
                });

                if(window.location.hash) {
                  var target = location.hash;
                  $('html,body').animate({ scrollTop: $(target).offset().top - 74}, 800 );
                }

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Services Template
        'page_template_template_services': {
            init: function() {

                $('.experience-tab').click(function(event) {
                    var experience = '#' + $(this).attr('data-experience');
                    $('.experience').hide();
                    $(experience).fadeIn();
                    $('.exp-btn').removeClass('active');
                    $('.exp-btn', event.currentTarget).addClass('active');
                    $('.container-fluid').addClass('grey-bg');
                });

                $('.scrollable').click(function(e){
                  e.preventDefault();
                  var target = '#'+$(this).attr("href").split('#')[1];

                  $('html,body').animate({scrollTop: $(target).offset().top - 74},800);
                });



                // Get the modal
                var modal = document.getElementById('myModal');
                var modalImg = document.getElementById("placeholder");

                $('.enlarge').click(function(){
                  modal.style.display = "block";
                  modalImg.src = $(this).attr('src');
                })

                // Close button
                var span = document.getElementsByClassName("close")[0];
                span.onclick = function() {
                  modal.style.display = "none";
                }


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    //bolding the first or lastword in headings
    $("#lastWord").html(function(){
          var text= $(this).text().trim().split(" ");
          var last = text.pop();
          return text.join(" ") + (text.length > 0 ? " <span class='strong'>" + last + "</span>" : last);
        });

        $("#firstWord").html(function(){
          var text= $(this).text().trim().split(" ");
          var first = text.shift();
          return (text.length > 0 ? "<span class='strong'>"+ first + "</span> " : first) + text.join(" ");
        });

})(jQuery); // Fully reference jQuery after this point.
